<template>
  <div class="hothouse animated fadeIn">
    <Zpeople />
  </div>
</template>
<script>
import Zpeople from "./hotpage/zpeople.vue";
export default {
  components: {
    Zpeople,
  },
  setup() {},
};
</script>
<style lang="scss" scoped>
.hothouse {
  width: 100%;
  height: 100%;
  background: #010615;
}
</style>
