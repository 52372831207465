<template>
  <div class="zpeople">
    <div class="box">
      <div class="imgBox">
        <img :src="img1" alt="" />
      </div>
      <div class="content">
        <h2>
          RPG-7<br />
          <span>人称7老板(无情加班机杀手)</span>
        </h2>
      </div>
    </div>
    <div class="box">
      <div class="imgBox">
        <img :src="img3" alt="" />
      </div>
      <div class="content">
        <h2>
          好大的钢板<br />
          <span>d，到处d</span>
        </h2>
      </div>
    </div>
    <div class="box">
      <div class="imgBox">
        <img :src="img2" alt="" />
      </div>
      <div class="content">
        <h2>
          总要找点乐子吧<br />
          <span>伊芙老师点歌机</span>
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
import img1 from "../../../assets/other/img1.png";
import img2 from "../../../assets/other/img2.png";
import img3 from "../../../assets/other/img3.png";
export default {
  setup() {
    return {
      img1,
      img2,
      img3,
    };
  },
};
</script>
<style lang="scss" scoped>
.zpeople {
  width: 100%;
  height: 100%;
  background: #010615;
}
.box {
  position: relative;
  width: 300px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  transition: 0.5s;
  background: #060c21;
  float: left;
}
.box .imgBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.box:hover {
  height: 500px;
}
.box .imgBox img {
  max-width: 100%;
  opacity: 0.1;
  transition: 0.5s;
}
.box:hover .imgBox img {
  opacity: 1;
}
.box:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #ffffff;
  z-index: -1;
}
.box:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #ffffff;
  z-index: -2;
  filter: blur(40px);
}
.box:before,
.box :after {
  background: linear-gradient(235deg, #89ff00, #010615, #00bcd4);
}
.box:nth-child(2):before,
.box:nth-child(2):after {
  background: linear-gradient(235deg, #ff005e, #010615, #fbff00);
}
.box:nth-child(3):before,
.box:nth-child(3):after {
  background: linear-gradient(235deg, #772aff, #010615, #2196fc);
}
.box .content {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  height: 90px;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  /*设置div盒子元素的背景透明度越靠近1越不透明*/
  transition: 0.5s;
  /*向下伸长实现效果时间*/
}

.box .content {
  bottom: 10px;
}

.box:hover .content {
  opacity: 1;
}
.box .content h2 {
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
}
.box .content h2 span {
  font-size: 14px;
  color: #ffffff;
  font-weight: 200;
  line-height: 20px;
  letter-spacing: 2px;
}
</style>
